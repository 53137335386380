<template>
	<div id="privacy" style="min-height: 100vh;">
        <v-head></v-head>
        <div class="container">
            <div class="banner">
                <h1 class="title">隱私政策</h1>
            </div>
            <div class="content">
                <section>
                    <h2>法律聲明：</h2>
                    <p>香港中醫在綫網站(以下稱本站)上刊載的所有內容，包括但不限於文字報導、圖片、聲音、錄影、圖表、標誌、標識、廣告、商標、商號、功能變數名稱、軟體、程式、版面設計、專欄目錄與名稱、內容分類標準以及為註冊用戶提供的任何或所有資訊，均受《中華人民共和國著作權法》、《中華人民共和國商標法》、《中華人民共和國專利法》及適用之國際公約中有關著作權、商標權、專利權或其它財產所有權法律的保護，為本站資訊中心及相關權利人專屬所有或持有。</p>
                    <p>使用者將本站提供的內容與服務用於非商業用途、非盈利、非廣告目的而純作個人消費時，應遵守著作權法以及其他相關法律的規定，不得侵犯本站或相關權利人的權利。</p>
                    <p>使用者將本站提供的內容與服務用於商業、盈利、廣告性目的時，需征得本站或相關權利人的書面特別授權，注明作者及文章出處"AAB國際"，並按有關國際公約和中華人民共和國法律的有關規定向相關權利人支付版稅。</p>
                    <p>未經本站的明確書面特別授權，任何人不得變更、發行、播送、轉載、複製、重制、改動、散佈、表演、展示或利用本站的局部或全部的內容或服務或在非本站所屬的伺服器上作鏡像，否則以侵權論，依法追究法律責任。特別地，本網站所使用的所有資訊歸屬本站所有， 受《中華人民共和國著作權法》及國際版權公約法律保護。除經本網站特別說明用作銷售或免費下載、使用等目的外，任何其他用途包括但不限於複製、修改、經銷、轉儲、發表、展示、演示以及反向工程均是嚴格禁止的。</p>
                </section>
                <section>
                    <h2>禁止條款：</h2>
                    <p>1. 在本站的發表的資訊必須遵守中華人民共和國各項法律、法規；遵守中國有關互聯網路的各項法規、條例。不發佈或連結有關政治、破壞系統、淫穢色情、封建迷信、人身攻擊等違法資訊；不使用論壇從事商業行為；否則本站有權刪除當事人帳號並取消其會員資格，當事人同時承擔由此直接或間接引起的刑事或民事法律責任。</p>
                    <p>2. 禁止使用者刪除或修改非本人許可權內的各種資料。</p>
                    <p>3. 禁止出於非法目的公開或使用任何個人資料和測評結果。由此產生的一切後果均由使用者負擔，本網站不負任何責任。 </p>
                </section>
                <section>
                    <h2>安全規則：</h2>
                    <p>禁止任何人破壞或干擾本站的正常運營，如未經許可進入或修改禁止訪問的的伺服器、帳戶或資料；企圖干涉或破壞對使用者及網路的服務等。本站一旦發現此類破壞行為，有權予與制止並保留起訴此類破壞行為責任人的權利。</p>
                </section>
                <section>
                    <h2>相關法律法規：</h2>
                    <p>本站不能保證所有的資訊均能完全滿足所有人員的要求。本網站提供資訊及方法的目的是為了更好地服務我們的訪問者，基於互聯網行業的現有技術水準，本網站並不保證所有項目的絕對準確性和完整性，所以僅供有相關需要者進行參考。</p>
                    <p>若您願與本網站進行某種形式的合作，敬請事先與我們取得聯絡，達成協議後方可實行，以期達到預期效果。</p>
                </section>
            </div>
        </div>
	</div>
</template>

<script>
    import vHead from '@/layouts/MobileHead.vue'
	export default {
		components: {
			vHead,
		}
	};
</script>

<style lang="scss" scoped>
    #privacy {
        width: 100%;
        padding-top: 94px;
        padding-left: 24px;
        padding-right: 24px;
        box-sizing: border-box;
        background-color: #EDEDED;
        display: flex;
        flex-direction: column;
    }

    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
    }

    .banner {
        width: 100%;
        height: 146px;
        background-image: url(../../../assets/imgs/mobile/img_background@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .banner > .title {
        font-size: 48px;
        font-weight: bold;
        color: #236271;
        margin-top: 45px;
        line-height: 42px;
        position: relative;
    }

    .banner > .title::before {
        content: '';
        position: absolute;
        left: -150px;
        top: 21px;
        width: 110px;
        height: 1px;
        background-color: #8DD2E3;
    }

    .banner > .title::after {
        content: '';
        position: absolute;
        right: -150px;
        top: 21px;
        width: 110px;
        height: 1px;
        transform: translateY(-50%);
        background-color: #8DD2E3;
    }

    .content {
        flex: 1;
        background-color: #fff;
        margin-top: 20px;
        color: #636363;
        font-size: 32px;
        font-weight: 400;
        line-height: 48px;
        display: flex;
        flex-direction: column;
        padding: 44px 25px;
    }
    
    .content section > h2 {
        font-weight: bold;
        font-size: 42px;
        color: #242020;
        line-height: 42px;
        margin-bottom: 20px;
    }
    .content p {
        color: #646464;
        font-size: 34px;
        font-weight: 400;
        line-height: 38px;
    }

    .content section {
        margin-bottom: 60px;
    }
</style>
